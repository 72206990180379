

// copyright auto date
document.getElementById("copyright-year").innerHTML = new Date().getFullYear();


// contact form
const form = document.querySelector("form"),
    statusTxt = form.querySelector(".button-area span");

form.onsubmit = (e) => {
    e.preventDefault();
    statusTxt.style.color = "#094D92";
    statusTxt.style.display = "block";
    statusTxt.innerText = "Sending your message...";
    form.classList.add("disabled");

    let xhr = new XMLHttpRequest();
    xhr.open("POST", "/email.php", true);
    xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {
            let response = xhr.response;
            if (response.indexOf("Email and message field is required!") != -1 || response.indexOf("Enter a valid email address!") != -1 || response.indexOf("Sorry, failed to send your message!") != -1) {
                statusTxt.style.color = "red";
            } else {
                form.reset();
                setTimeout(() => {
                    statusTxt.style.display = "none";
                }, 3000);
            }
            statusTxt.innerText = response;
            form.classList.remove("disabled");
        }
    }
    let formData = new FormData(form);
    xhr.send(formData);
}




// theme


// const txtThemeDark = document.getElementsByClassName('txt-theme-dark')[0];
// const txtThemeLight = document.getElementsByClassName('txt-theme-light')[0];

const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
const userPrefersLight = window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches;

const themeCheckbox = document.getElementById('theme-slider');
//////////////////
/*
// const themeToggle = document.querySelector("#theme-toggle");

const currentTheme = localStorage.getItem("theme");
var pageTheme = document.body;

let isDark = true

if (currentTheme == "dark") {
    pageTheme.classList.add("theme-dark");
    pageTheme.classList.remove("theme-light");
    themeCheckbox.innerText = "🌞";
} else {
    themeCheckbox.innerText = "🌚"
}

function themeMode() {
    isDark = !isDark;
    isDark ? themeCheckbox.innerText = "🌚" : themeCheckbox.innerText = "🌞";
    pageTheme.classList.toggle("theme-dark");
    // pageTheme.classList.remove("theme-dark");
    // pageTheme.classList.add("theme-light");

    let theme = "theme";
    if (pageTheme.classList.contains("theme-dark")) {
        theme = "dark";
    }
    localStorage.setItem("theme", theme);
}

themeCheckbox.addEventListener("click", themeMode)
*/
////////////////////


if (userPrefersDark) {
    // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // if (userPrefersDark) {
    // console.log('prefers dark');
    themeCheckbox.checked = true;
    setTheme('theme-dark');
} else {
    // console.log('prefers light');
    themeCheckbox.checked = false;
    setTheme('theme-light');
}



// checking what user has in os first
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    const newColorScheme = event.matches ? "dark" : "light";
    // console.log('event listen');

    // check if user already has a preference and use it (ignores refreshes since it reverts to pref of user/system)
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // if (userPrefersDark) {
        // console.log('already prefers light');
        setTheme('theme-dark');
        themeCheckbox.checked = true;
    } else {
        // console.log('already prefers light');
        setTheme('theme-light');
        themeCheckbox.checked = false;
    }

});



// function to set a given theme/color-scheme
function setTheme(themeName) {
    localStorage.setItem('theme', themeName);
    // document.documentElement.className = themeName;
    document.body.className = themeName;
}

// function to toggle between light and dark theme
function toggleTheme() {
    if (localStorage.getItem('theme') === 'theme-dark') {
        setTheme('theme-light');
        // console.log('btn prefers light');
        themeCheckbox.checked = false;
    } else {
        setTheme('theme-dark');
        // console.log('btn prefers dark');
        themeCheckbox.checked = true;
    }
}

// Immediately invoked function to set the theme on initial load
(function () {
    if (localStorage.getItem('theme') === 'theme-dark') {
        setTheme('theme-dark');
        themeCheckbox.checked = true;
        // console.log('dark theme');
    } else {
        setTheme('theme-light');
        themeCheckbox.checked = false;
        // console.log('light theme');
    }
})();

/*

*/












var Emblem = {
    init: function init(e, t) {
        var a = document.querySelector(e),
            n = t || a.innerHTML;
        a.innerHTML = "";

        for (var i = 0; i < n.length; i++) {
            var o = n[i],
                s = document.createElement("span"),
                l = document.createTextNode(o),
                r = 360 / n.length * i,
                c = (Math.PI / n.length).toFixed(0) * i,
                m = (Math.PI / n.length).toFixed(0) * i;
            s.appendChild(l), s.style.webkitTransform = "rotateZ(" + r + "deg) translate3d(" + c + "px," + m + "px,0)", s.style.transform = "rotateZ(" + r + "deg) translate3d(" + c + "px," + m + "px,0)", a.appendChild(s);
        }
    }
};
Emblem.init(".emblem");



document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
            block: "center"
        });
    });
});


/*
let anchorlinks = document.querySelectorAll('a[href^="#"]')
 
for (let item of anchorlinks) { // relitere 
    item.addEventListener('click', (e)=> {
        let hashval = item.getAttribute('href')
        let target = document.querySelector(hashval)
        target.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
        history.pushState(null, null, hashval)
        e.preventDefault()
    })
}
*/



















window.onscroll = () => {
    toggleTopButton();
}
function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

function toggleTopButton() {
    if (document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150) {
        // document.getElementById('back-to-up').classList.remove('d-none');
        document.getElementById("js-top").classList.remove("hide");
        document.getElementById("js-top").classList.add("show");
    } else {
        // document.getElementById('back-to-up').classList.add('d-none');
        document.getElementById("js-top").classList.add("hide");
        document.getElementById("js-top").classList.remove("show");
    }
}






/*

var bee = document.getElementById("bee");
document.addEventListener("mousemove", getMouse);


bee.style.position = "absolute"; //css		
var beepos = { x: 0, y: 0 };

setInterval(followMouse, 50);

var mouse = { x: 0, y: 0 }; //mouse.x, mouse.y

var dir = "right";
function getMouse(e) {
    mouse.x = e.pageX;
    mouse.y = e.pageY;
    //Checking directional change
    if (mouse.x > beepos.x) {
        dir = "right";
    } else {
        dir = "left";
    }
}

function followMouse() {
    //1. find distance X , distance Y
    var distX = mouse.x - beepos.x;
    var distY = mouse.y - beepos.y;
    //Easing motion
    //Progressive reduction of distance 
    beepos.x += distX / 5;
    beepos.y += distY / 2;

    bee.style.left = beepos.x + "px";
    bee.style.top = beepos.y + "px";


    //Apply css class 
    if (dir == "right") {
        bee.setAttribute("class", "right");
    } else {
        bee.setAttribute("class", "left");
    }

}

*/

const cursor = document.querySelector("#cursor");
const cursorBorder = document.querySelector("#cursor-border");
const cursorPos = { x: 0, y: 0 };
const cursorBorderPos = { x: 0, y: 0 };

document.addEventListener("mousemove", (e) => {
    cursorPos.x = e.clientX;
    cursorPos.y = e.clientY;

    cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
});

requestAnimationFrame(function loop() {
    const easing = 5;
    cursorBorderPos.x += (cursorPos.x - cursorBorderPos.x) / easing;
    cursorBorderPos.y += (cursorPos.y - cursorBorderPos.y) / easing;

    cursorBorder.style.transform = `translate(${cursorBorderPos.x}px, ${cursorBorderPos.y}px)`;
    requestAnimationFrame(loop);
});

document.querySelectorAll("[data-cursor]").forEach((item) => {
    item.addEventListener("mouseover", (e) => {
        if (item.dataset.cursor === "pointer") {
            cursorBorder.style.backgroundColor = "rgba(255, 255, 255, .6)";
            cursorBorder.style.setProperty("--size", "30px");
        }
        if (item.dataset.cursor === "pointer2") {
            cursorBorder.style.backgroundColor = "white";
            cursorBorder.style.mixBlendMode = "difference";
            cursorBorder.style.setProperty("--size", "80px");
        }
    });
    item.addEventListener("mouseout", (e) => {
        cursorBorder.style.backgroundColor = "unset";
        cursorBorder.style.mixBlendMode = "unset";
        cursorBorder.style.setProperty("--size", "50px");
    });
});

/*

const math = {
    lerp: (a, b, n) => {
        return (1 - n) * a + n * b
    }
}

class Cursor {
    constructor() {
        this.el = document.querySelector('[data-cursor]')
        this.stickies = [...document.querySelectorAll('[data-stick-cursor]')]

        this.data = {
            mouse: {
                x: 0,
                y: 0
            },
            current: {
                x: 0,
                y: 0
            },
            last: {
                x: 0,
                y: 0
            },
            ease: 0.15,
            dist: 100,
            fx: {
                diff: 0,
                acc: 0,
                velo: 0,
                scale: 0
            }
        }

        this.bounds = {
            h: 0,
            a: 0
        }

        this.rAF = null
        this.targets = null

        this.run = this.run.bind(this)
        this.mousePos = this.mousePos.bind(this)
        this.stick = this.stick.bind(this)

        this.state = {
            stick: false
        }

        this.init()
    }

    mousePos(e) {
        this.data.mouse.x = e.pageX
        this.data.mouse.y = e.pageY

        this.data.current.x = e.pageX
        this.data.current.y = e.pageY
    }

    getCache() {
        this.targets = []

        this.stickies.forEach((el, index) => {
            const bounds = el.getBoundingClientRect()

            this.targets.push({
                el: el,
                x: bounds.left + bounds.width / 2,
                y: bounds.top + bounds.height / 2
            })
        })
    }

    stick(target) {
        const d = {
            x: target.x - this.data.mouse.x,
            y: target.y - this.data.mouse.y
        }

        const a = Math.atan2(d.x, d.y)
        const h = Math.sqrt(d.x * d.x + d.y * d.y)

        if (h < this.data.dist && !this.state.stick) {

            this.state.stick = true
            this.data.ease = 0.075

            this.data.current.x = target.x - Math.sin(a) * h / 2.5
            this.data.current.y = target.y - Math.cos(a) * h / 2.5

            this.el.classList.add('is-active')
        } else if (this.state.stick) {

            this.state.stick = false
            this.data.ease = 0.15
        } else if (h > this.data.dist) {

            this.el.classList.remove('is-active')
        }
    }

    run() {
        this.targets.forEach(this.stick)

        this.data.last.x = math.lerp(this.data.last.x, this.data.current.x, this.data.ease)
        this.data.last.y = math.lerp(this.data.last.y, this.data.current.y, this.data.ease)

        this.data.fx.diff = this.data.current.x - this.data.last.x
        this.data.fx.acc = this.data.fx.diff / window.innerWidth
        this.data.fx.velo = + this.data.fx.acc
        this.data.fx.scale = 1 - Math.abs(this.data.fx.velo * 5)

        this.el.style.transform = `translate3d(${this.data.last.x}px, ${this.data.last.y}px, 0) scale(${this.data.fx.scale})`

        this.raf()
    }

    raf() {
        this.rAF = requestAnimationFrame(this.run)
    }

    addListeners() {
        window.addEventListener('mousemove', this.mousePos, { passive: true })
    }

    init() {
        this.getCache()
        this.addListeners()
        this.raf()
    }
}

const cursor = new Cursor()

*/

